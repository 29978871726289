<template>
  <div class="zt-component-main">
    <div class="component-label" style="display: inline-block;">
      <slot></slot>
      {{ widget.componentName }}：
    </div>
    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ countryCodeComputed }}</div>
    <!-- <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ computedWidgetCustomContent }}</div>-->

    <mainland-user-component v-if="computedType == '二代身份证'"></mainland-user-component>
    <hongkong-and-macao-user-component v-if="computedType == '港澳居民来往内地通行证'"></hongkong-and-macao-user-component>
    <taiwan-user-component v-if="computedType == '台湾居民来往大陆通行证'"></taiwan-user-component>
    <foreign-user-component v-if="computedType == '护照'"></foreign-user-component>
  </div>
</template>

<script type="text/ecmascript-6">

import ComponentMixin                      from '../component-mixin'
import {isEmpty}                           from 'lodash'
import {pureGetProvinceAndCityAndDistrict} from '@/utils/cz-utils'
import ForeignUserComponent                from './foreign-user-component.vue'
import HongkongAndMacaoUserComponent       from './hongkong-and-macao-user-component.vue'
import MainlandUserComponent               from './mainland-user-component.vue'
import TaiwanUserComponent                 from './taiwan-user-component.vue'

export default {
  provide() {
    return {
      ZtCountryAdvance: this
    };
  },
  extends   : ComponentMixin,
  mixins    : [],
  components: {
    ForeignUserComponent,
    HongkongAndMacaoUserComponent,
    MainlandUserComponent,
    TaiwanUserComponent,
  },
  name      : 'ZtCountryAdvance',
  props     : {
    className: { type: String, default: '' },
    widget   : { type: Object, required: true },
  },
  data() {
    return {
      pcaText: '',
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    computedType() {
      let nationality = this.answerJsonValue.nationality

      if (!nationality) {return ''}

      if (nationality == 'CN') {return '二代身份证'}

      if (nationality == 'HK') {return '港澳居民来往内地通行证'}

      if (nationality == 'MO') {return '港澳居民来往内地通行证'}

      if (nationality == 'TW') {return '台湾居民来往大陆通行证'}

      return '护照'
    },
    cardType() {
      let cardType = this.answerJsonValue.cardType
      if (cardType === undefined || cardType === null || cardType === '') {
        return null
      }
      else {
        return cardType
      }
    },
    getCountryList() {
      return this.$store.state.components.countryList
    },
    answerJsonValue() {
      let computedWidgetCustomContent = this.computedWidgetCustomContent
      if (computedWidgetCustomContent === undefined || computedWidgetCustomContent === null || computedWidgetCustomContent === '') {
        return {}
      }

      if (Object.prototype.toString.call(this.computedWidgetCustomContent) === '[object String]') {
        return JSON.parse(this.computedWidgetCustomContent)
      }
      else {
        return this.computedWidgetCustomContent
      }
    },
    countryCodeComputed() {
      let findItem = this.getCountryList.find((item) => {
        return item.itemCode === this.answerJsonValue.nationality
      })
      if (findItem) {
        return findItem.cnName
      }
      else {
        return this.answerJsonValue
      }
    },
    nativeComputed() {
      let native = this.answerJsonValue.native
      if (native === undefined || native === null || native === '') {
        return ''
      }
      else {
        let pcaValue       = JSON.parse(native)
        let pcaStringValue = pureGetProvinceAndCityAndDistrict([
          pcaValue.provinceCode,
          pcaValue.cityCode,
          pcaValue.districtCode,
        ])

        return pcaStringValue
      }
    },
  },
  methods : {
    //

  },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {
    if (isEmpty(this.widget)) {throw new Error('widget is empty')}
    if (this.widget && Reflect.has(this.widget, 'answer') && this.widget.answer) {
      let answerJsonValue = JSON.parse(this.widget.answer)

      const foo = {
        'birthday'      : '1992-05-26',
        'nationality'   : 'CN',
        'residentialImg': '/changzheng/1002110021000193264/9c6350d8d5c44777ac5bef51d4ffaf23.jpg',
        'native'        : '{"districtCode":"130102000000","provinceCode":"130000","cityCode":"130100000000"}',
        'cardType'      : '0',
        'cardFrontImg'  : '/changzheng/1002110021000193264/92e15f5dad164e6494fe5e9a51a79a73.jpg',
        'cardNumber'    : '130102199205260614',
        'cardReverseImg': '/changzheng/1002110021000193264/9cbd76ab51ac41c9a6264c178599ac84.jpg',
      }

      console.log('【ZtCountryAdvance:76】', `answerJsonValue = `, answerJsonValue)

      if (answerJsonValue.native === undefined || answerJsonValue.native === null || answerJsonValue.native === '') {

      }
      else {
        let pcaValue = JSON.parse(answerJsonValue.native)
        console.log('【ZtCountryAdvance:82】', `pcaValue = `, pcaValue)

        let pcaStringValue = pureGetProvinceAndCityAndDistrict([
          pcaValue.provinceCode,
          pcaValue.cityCode,
          pcaValue.districtCode,
        ])
        console.log('【ZtCountryAdvance:110】', `pcaStringValue = `, pcaStringValue)
      }

      // let { provinceCode, cityCode, districtCode } = pcaValue
      // let pcaText                                  = this.getProvinceAndCityAndDistrict([provinceCode, cityCode, districtCode])
      // this.pcaText                                 = pcaText
    }
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "../component.scss";
</style>
