export function pureGetProvinceAndCityAndDistrict(list, separator = ' ') {
  if (Object.prototype.toString.call(list) == '[object Array]') {
    if (list.length == 3) {
      let provinceValue = list[0]
      let cityValue     = list[1]
      let districtValue = list[2]
      let province      = window.pcaOptions.find(item => item.value == provinceValue)
      if (!province) {return '未知'}
      let city = province.children.find(item => item.value == cityValue)
      if (!city) {return `${province.label}${separator}`}
      let district = city.children.find(item => item.value == districtValue)
      if (!district) {
        return `${province.label}${separator}${city.label}${separator}`
      }
      else {
        return `${province.label}${separator}${city.label}${separator}${district.label}`
      }
    }
    else {
      return ''
    }
  }
  else {
    return ''
  }
}
